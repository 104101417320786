@font-face{
	font-family: NeueMachinaUltraBold;
	src: url('./fonts/PPNeueMachina-PlainUltrabold.ttf');
}

@font-face{
	font-family: NeueMachinaRegular;
	src: url('./fonts/PPNeueMachina-PlainRegular.ttf');
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.amplify-tabs {
	display: none;
}

/* For pdf */
.react-pdf__Page {
	margin-top: 10px;
}
.react-pdf__Page__textContent {
	border: 1px solid darkgrey;
	box-shadow: 5px 5px 5px 1px #ccc;
	border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
	padding: 20px;
}

.react-pdf__Page__canvas {
	margin: 0 auto;
}

[data-amplify-authenticator] {
	--amplify-components-authenticator-router-border-style: none;
	--amplify-components-authenticator-router-box-shadow: none;
	--amplify-fonts-default-static: "Roboto";
	--amplify-components-button-link-focus-box-shadow: none;
}

.amplify-button--primary {
	text-transform: uppercase;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
